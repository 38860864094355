import styles from '../../../pages/area-privada/index.module.scss'
import CitaCard from './CitaCard'
import StandardModal from '../../modal/standardModal/StandardModal'
import { t } from '../../../i18n'
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { retrieveCitaInfo } from '../../../context/actions/citaPreviaActions'
import { navigate } from 'gatsby'
import route from '../../../utils/route'
import { IAppState } from '../../../context/context'
import { getCitasPrevias } from '../../../context/selectors/areaPrivadaSelector'
import { ICitaPrevia } from '../../../types/CitaPrevia'

interface Props {
  citasPrevias: ICitaPrevia[]
}
const CitasWrapper = ({ citasPrevias }: Props) => {
  const [selectedCita, setSelectedCita] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()
  const modifyCita = async (cita: ICitaPrevia) => {
    if (cita.type === 'CITA PREVIA') {
      dispatch(retrieveCitaInfo(cita.id_site_citas_previas))
      await navigate(route('cita-previa.modificar'))
    }
    if (cita.type === 'VENTA') {
      setShowModal(true)
      setSelectedCita(cita.id)
    }
  }

  return (
    <React.Fragment>
      {citasPrevias && citasPrevias.length ? (
        <h3 className={styles.cita_title}>
          {t('area_privada.left_section.proxima_cita')}
        </h3>
      ) : null}
      <div className={styles.citas}>
        {citasPrevias
          ? citasPrevias.map((item, index) => (
              <CitaCard modifyCita={modifyCita} key={index} cita={item} />
            ))
          : null}
      </div>
      <StandardModal
        modalClass={styles.cita_modal}
        title="Modificar Cita"
        showModal={showModal}
        closeModal={() => setShowModal(false)}>
        <p>
          {t('cita_previa.modificar.line_one', { id_pedido: selectedCita })}
        </p>
        <p>{t('cita_previa.modificar.line_two')}</p>
      </StandardModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IAppState) => ({
  citasPrevias: getCitasPrevias(state),
})
export default connect(mapStateToProps)(CitasWrapper)
