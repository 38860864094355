import React from 'react'
import styles from './index.module.scss'
import Phone from '../../../assets/phone.svg'
import LocationIcon from '../../../assets/images/area_privada/icon_ubicacion5.svg'
import CitaIcon from '../../../assets/images/area_privada/icono_cita.svg'
import { t, textWithLocale } from '../../../i18n'
import Mantenimiento from '../../../assets/cita-previa/icon_mantenimiento.svg'
import Neumaticos from '../../../assets/cita-previa/icon_neumaticos.svg'
import Revisiones from '../../../assets/cita-previa/icon_preitv.svg'
import Climatizacion from '../../../assets/cita-previa/icon_climatizacion.svg'
import Mecanica from '../../../assets/cita-previa/icon_mecanica.svg'
import Frenos from '../../../assets/cita-previa/icon_frenos.svg'
import Electricidad from '../../../assets/cita-previa/icon_electricidad.svg'
import Diagnosis from '../../../assets/cita-previa/icon_diagnosis.svg'
import Transmision from '../../../assets/cita-previa/icon_transmision.svg'
import Accesorios from '../../../assets/cita-previa/icon_accesorios.svg'
import { ICitaPrevia } from '../../../types/CitaPrevia'

const icon = (item) => {
  switch (item) {
    case 'nemm':
    case 'neum':
    case 'Neumatico':
    case 'Neumatico y Revision':
      return <Neumaticos />
    case 'itvm':
    case 'itv':
      return <Revisiones />
    case 'manm':
    case 'mant':
    case 'Revision':
      return <Mantenimiento />
    case 'mecm':
    case 'meca':
      return <Mecanica />
    case 'frem':
    case 'fre':
      return <Frenos />
    case 'elem':
    case 'elec':
      return <Electricidad />
    case 'cli':
      return <Climatizacion />
    case 'diag':
      return <Diagnosis />
    case 'tranm':
      return <Transmision />
    case 'accm':
      return <Accesorios />
    default:
      return null
  }
}

interface Props {
  cita: ICitaPrevia
  modifyCita: (cita: ICitaPrevia) => void
}
export default function CitaCard({ cita, modifyCita }: Props) {
  if (cita) {
    return (
      <div className={styles.cita_card}>
        <span className={styles.tipo_cita}>
          {icon(cita.operation.type)}{' '}
          {t('area_privada.left_section.tipo_cita', {
            operation: cita.operation[textWithLocale('nombre')],
          })}
        </span>
        <div className={styles.cita_card_address}>
          <span className={styles.location_icon}>
            <LocationIcon />
          </span>
          <div className={styles.cita_card_address_info}>
            <span>{cita.alias}</span>
            <span>{cita.direccion}</span>
            <span>
              {cita.poblacion} ({cita.provincia}) - {cita.cpostal}
            </span>
          </div>
          <p>
            <Phone className={styles.phone_icon} /> {cita.telefono}
          </p>
        </div>
        <div className={styles.cita_card_date}>
          <div className={styles.cita_info_wrapper}>
            <span>
              <CitaIcon className={styles.cita_icon} />
            </span>
            <span className={styles.fecha_y_hora}>
              {cita.fecha} a las {cita.hora}
            </span>
          </div>
          <span
            onClick={() => modifyCita(cita)}
            className={styles.cambiar_fecha}>
            {t('area_privada.left_section.cambiar_hora')}
          </span>
        </div>
      </div>
    )
  }
  return null
}
