import { graphql, navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Car from '../../../common/assets/images/area_privada/icon_coche.svg'
import {
  AreaPrivadaCitaPreviaEnum,
  areaPrivadaCitaSteps,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import Step, {
  StepDetails,
} from '../../../common/components/FunnelSelector/SelectorSteps'
import Layout from '../../../common/components/Layout'
import AreaPrivadaLayout from '../../../common/components/layouts/areaPrivadaLayout/AreaPrivadaLayout'
import SpinnerWithText from '../../../common/components/spinnerWithText/SpinnerWithText'
import RetrieveVehicleSuccess from '../../../common/components/vehicleFinder/retrieveVehicleSuccess/RetrieveVehicleSuccess'
import VehicleFinder from '../../../common/components/vehicleFinder/VehicleFinder'
import * as AltaVehiculoActions from '../../../common/context/actions/altaVehiculoActions'
import {
  resetCitaPrevia,
  setCitaPreviaStep,
  setSelectedService,
  setUserData,
} from '../../context/actions/citaPreviaActions'
import { reset } from '../../context/actions/selectorActions'
import * as UserActions from '../../../common/context/actions/userActions'
import AppContext from '../../../common/context/context'
import { locale, t } from '../../i18n'
import { getImages } from '../../utils/imagesS3'
import route from '../../../common/utils/route'
import { alertProp } from '../../utils/swal'
import { SeoData } from '../../types/Seo'
import styles from './index.module.scss'
import { SelectedService } from '../../context/reducers/citaPreviaReducer'
import ResponsiveSliderBanner from '../../components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import { getTarifa } from '../../utils/getTarifa'
import {
  findCitasPrevias,
  findServices,
  findVehicles,
  resetError,
  setTaller,
} from '../../context/actions/areaPrivadaActions'
import VehicleCard from '../../components/VehicleCard/VehicleCard'
import { Vehicle } from '../../types/Vehiculo'
import CitasWrapper from '../../components/areaClientes/citaPrevia/CitasWrapper'

const placeholderImage = {
  Key: '',
  url: '',
  link: '',
  alt: '',
}

interface Props {
  data: {
    seoData: SeoData
    imagen_ca_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_es_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_ca_mobile: {
      nodes: { key: string; url: string }[]
    }
    imagen_es_mobile: {
      nodes: { key: string; url: string }[]
    }
    imagen_pt_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_pt_mobile: {
      nodes: { key: string; url: string }[]
    }
  }
}

const Content: React.FC<Props> = ({ data }) => {
  const [clientVehicleData, setClientVehicle] = useState({})
  const [showSuccessMessage, setshowSuccessMessage] = useState(false)

  const {
    userInfo,
    citaPreviaState,
    dispatch,
    areaPrivadaState: localState,
  } = useContext(AppContext)

  // There are some specific banners
  // that only will be shown to Galicia clients
  let tarifa
  if (userInfo.postCode) {
    tarifa = getTarifa(userInfo.postCode)
  }

  const banners_desktop =
    tarifa === 'gal'
      ? getImages(
          data[`imagen_${locale}_desktop`].nodes,
          locale,
          placeholderImage
        )
      : getImages(
          data[`imagen_${locale}_desktop`].nodes,
          locale,
          placeholderImage
        ).filter((banner) => !banner.Key.includes('gal_'))
  const banners_mobile =
    tarifa === 'gal'
      ? getImages(
          data[`imagen_${locale}_mobile`].nodes,
          locale,
          placeholderImage
        )
      : getImages(
          data[`imagen_${locale}_mobile`].nodes,
          locale,
          placeholderImage
        ).filter((banner) => !banner.Key.includes('gal_'))

  const retrievedVehicleData = (vehicleData) => {
    setClientVehicle(vehicleData)
    setshowSuccessMessage(true)
    dispatch(findVehicles())
  }

  useEffect(() => {
    dispatch(AltaVehiculoActions.setAltaVehiculoStep(1))
    dispatch(resetCitaPrevia())
    dispatch(findVehicles())
    dispatch(setTaller())
    dispatch(findCitasPrevias())
    dispatch(findServices())
    if (!userInfo.percentCompleted) {
      dispatch(UserActions.getUserData())
    }

    // get selector values in case user goes straight into area privada
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('cesta.solicitud'),
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        tipoVehiculo: null,
      })
    )
  }, [])

  const selectorModalSubmit = async () => {
    await navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop)
  }

  const onSelectService = async (
    selectedService: SelectedService,
    vehicle: Vehicle,
    device: 'MOBILE' | 'TABLET' | 'DESKTOP'
  ) => {
    const url = document.location.href
    dispatch(setSelectedService(selectedService, url))
    dispatch(
      setUserData({
        email: userInfo.email,
        name: userInfo.userName,
        phone: userInfo.phone,
        comment: '',
        device,
        marca: vehicle.marca,
        matricula: vehicle.cod_matricula,
        modelo: vehicle.modelo,
        origin: '/area-privada/',
      })
    )
    dispatch(
      setCitaPreviaStep(
        areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT].step
      )
    )
    await navigate(
      areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT].route,
      {
        state: { area_privada: true },
      }
    )
  }

  if (localState.error) {
    dispatch(resetError())
    Swal.fire(
      alertProp({
        type: 'error',
        title: 'Oops',
        text: localState.error,
      })
    )
  }

  return (
    <React.Fragment>
      <CitasWrapper />
      {showSuccessMessage && (
        <RetrieveVehicleSuccess clientVehicleData={clientVehicleData} />
      )}
      <div>
        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
        />
      </div>
      <article className={styles.mis_vehiculos}>
        <div className={styles.mis_vehiculos_intro}>
          <span className={styles.mis_vehiculos_text}>
            {t('area_privada.left_section.vehiculos')}
          </span>
          {localState.vehicles && localState.vehicles.length ? (
            <VehicleFinder
              origin="privada"
              retrievedVehicleData={retrievedVehicleData}
            />
          ) : null}
        </div>

        {localState.vehicleLoading ? (
          <SpinnerWithText text={t('revisiones.cargando')} />
        ) : localState.vehicles && localState.vehicles.length ? (
          localState.vehicles.map((item, key) => (
            <VehicleCard
              key={`area-privada_vehicleCard${key}`}
              matricula={item.vehicle.cod_matricula}
              selectorModalSubmit={selectorModalSubmit}
              services={localState.availableServices}
              onSelectService={onSelectService}
              currentSelection={
                citaPreviaState.selectedService
                  ? citaPreviaState.selectedService.service
                  : null
              }
            />
          ))
        ) : (
          <div className={styles.add_vehicle_card}>
            <Car />
            <VehicleFinder
              origin="privada"
              retrievedVehicleData={retrievedVehicleData}
            />
          </div>
        )}
      </article>
    </React.Fragment>
  )
}

export default function Index({ data }: Props) {
  return (
    <Layout
      requireLogin={true}
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.area_privada'),
          link: route('area-privada.index'),
        },
      ]}>
      <AreaPrivadaLayout title={t('area_privada.title')}>
        <Content data={data} />
      </AreaPrivadaLayout>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AreaPrivadaQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    imagen_ca_desktop: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/ca/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_ca_mobile: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/ca/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_es_desktop: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/es/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_es_mobile: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/es/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_pt_desktop: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_pt_mobile: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
