import React from 'react'
import Matricula from '../../assets/images/area_privada/icon_matricula.svg'
import styles from './index.module.scss'

interface MatriculaIconProps {
  text: string
}

export default function MatriculaIcon({ text }: MatriculaIconProps) {
  return (
    <div className={styles.matricula_icon}>
      <Matricula />
      <span>{text}</span>
    </div>
  )
}
