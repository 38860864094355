import React from 'react'
import styles from './index.module.scss'
import success from '../../../../common/images/gracias-por-tu-confianza.png'
import { FormattedMessage } from 'react-intl'
import { t } from '../../../i18n'
import { Vehicle } from '../../../types/Vehiculo'

interface Props {
  clientVehicleData: {
    [vehicleData: string]: Vehicle
  }
}
export default function RetrieveVehicleSuccess({ clientVehicleData }: Props) {
  const { vehicleData } = clientVehicleData
  return (
    <div className={styles.vehicle_added_card}>
      <p className={styles.vehicle_added_card_header}>
        <img src={success} alt={t('alta_vehiculo.anadido')} />{' '}
        {t('alta_vehiculo.anadido', { marca: vehicleData.marca })}
      </p>
      <p className={styles.info}>
        <span className={styles.title}>
          <FormattedMessage id="alta_vehiculo.modelo2" />
        </span>{' '}
        {vehicleData.modelo || ''}{' '}
      </p>
      <p className={styles.info}>
        <span className={styles.title}>
          <FormattedMessage id="alta_vehiculo.ano_matricula" />
        </span>{' '}
        {vehicleData.ano_matric || ''}
      </p>
      <p className={styles.info}>
        <span className={styles.title}>
          <FormattedMessage id="alta_vehiculo.matricula" />
        </span>{' '}
        {vehicleData.cod_matricula || ''}
      </p>
    </div>
  )
}
