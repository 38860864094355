import { Aceite } from '../../types/Aceite'
import { NeumaticoMatricula } from '../../types/Neumatico'
import styles from './vehicleCard.module.scss'
import { t } from '../../i18n'
import InfoIcon from '../infoIcon/InfoIcon'
import moment from 'moment/moment'
import React from 'react'
import { connect } from 'react-redux'
import {
  getAceiteByMatricula,
  getNeumaticoByMatricula,
} from '../../context/selectors/areaPrivadaSelector'
import { IAppState } from '../../context/context'

interface MoreInfoTextProps {
  matricula: string
  aceite: Aceite | null
  neumatico: NeumaticoMatricula | null
}
const MoreInfoText = ({ aceite, neumatico }: MoreInfoTextProps) => {
  return (
    <div className={styles.more_info}>
      <div className={styles.group}>
        <span>
          {t('area_privada.left_section.ultima_revision')}: &nbsp;
          <span className={styles.infoIcon_desktop}>
            {aceite ? (
              <p>{moment(aceite.fecha).format('DD/MM/YYYY')}</p>
            ) : (
              <InfoIcon
                text={t('area_privada.left_section.ultima_revision_help')}
              />
            )}
          </span>
        </span>
      </div>
      <div className={styles.group}>
        <span>
          {t('area_privada.left_section.ultimos_neumaticos')}
          :&nbsp;
          <span className={styles.infoIcon_desktop}>
            {neumatico ? (
              <span>
                {moment(neumatico.fecha).format('DD/MM/YYYY')}
                &nbsp;-&nbsp;
                {neumatico.marca}
              </span>
            ) : (
              <InfoIcon text={t('area_privada.left_section.neumaticos_help')} />
            )}
          </span>
        </span>
      </div>
    </div>
  )
}

const mapStateToProps = (state: IAppState, ownProps) => ({
  aceite: getAceiteByMatricula(state, ownProps.matricula),
  neumatico: getNeumaticoByMatricula(state, ownProps.matricula),
})
export default connect(mapStateToProps)(MoreInfoText)
