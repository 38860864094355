import React, { useState } from 'react'
import Flecha from '../../assets/arrow.svg'
import { t } from '../../i18n'
import MatriculaIcon from '../matriculaIcon/MatriculaIcon'
import styles from './vehicleCard.module.scss'
import ServicesList from '../CitaPrevia/Servicios/ServicesList/ServicesList'
import { ServiceTaller } from '../CitaPrevia/Servicios/Servicios'
import { SelectedService } from '../../context/reducers/citaPreviaReducer'
import VehicleCardActions from './VehicleCardActions'
import MoreInfoText from './MoreInfoText'
import { IAppState } from '../../context/context'
import { getVehicleByMatricula } from '../../context/selectors/areaPrivadaSelector'
import { connect } from 'react-redux'
import { Vehicle } from '../../types/Vehiculo'

interface VehicleCardProps {
  matricula: string
  vehicle: Vehicle
  selectorModalSubmit?: () => void
  onMoreInfo?: (service: ServiceTaller) => void
  services?: ServiceTaller[]
  onSelectService?: (selectedService: SelectedService, vehicle, device) => void
  currentSelection?: string | null
}

const VehicleCard = ({
  vehicle,
  onMoreInfo,
  services,
  onSelectService,
  currentSelection,
}: VehicleCardProps) => {
  const [moreInfo, setMoreInfo] = useState(false)
  const toggleMoreInfo = () => {
    setMoreInfo(!moreInfo)
  }

  const {
    cod_matricula,
    ano_matric,
    marca,
    combustible_tipo,
    id_marca,
    modelo,
    imagen,
  } = vehicle || {}

  let MarcaLogo
  try {
    MarcaLogo = require(`!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../assets/images/logos_marcas_coches/${id_marca}.svgz`)
  } catch {
    //
  }

  let imageVehicle
  if (imagen && imagen.startsWith('http')) {
    imageVehicle = imagen.replace(/^http:/, 'https:')
  }
  return (
    <div className={styles.container}>
      <div className={styles.image_info}>
        <div className={styles.image}>
          {imageVehicle && (
            <div
              className={styles.imgCar}
              style={{
                backgroundImage: imagen ? `url(${imageVehicle})` : '',
              }}
            />
          )}
          {MarcaLogo && (
            <img className={styles.marca_img} src={MarcaLogo.default} />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <span className={styles.marca}>
              {marca} {modelo}
            </span>
            <span
              className={`${styles.arrow} ${
                moreInfo ? styles.arrow_clicked : ''
              }`}
              onClick={toggleMoreInfo}>
              <Flecha />
            </span>
            <span className={styles.combustible}>
              {combustible_tipo} {t('area_privada.left_section.año')}{' '}
              {ano_matric}
            </span>
            <span className={styles.matricula_label}>
              {t('area_privada.left_section.matricula')}
            </span>
            <MatriculaIcon text={cod_matricula} />
          </div>
          <span className={styles.more_info_desktop}>
            <MoreInfoText matricula={cod_matricula} />
          </span>
          <div className={styles.actions_desktop}>
            <VehicleCardActions moreInfo={moreInfo} matricula={cod_matricula} />
          </div>
        </div>
      </div>

      <div className={styles.moreInfo_mobile}>
        <MoreInfoText matricula={cod_matricula} />
      </div>

      <div className={styles.actions_mobile}>
        <VehicleCardActions moreInfo={moreInfo} matricula={cod_matricula} />
      </div>
      <div className={styles.services_container}>
        {t('area_privada.right_section.pedir_cita')}
        <ServicesList
          onMoreInfo={onMoreInfo}
          activeTab={'Coche'}
          services={services}
          onSelectService={onSelectService}
          currentSelection={currentSelection}
          device={'DESKTOP'}
          vehicle={vehicle}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: IAppState, ownProps) => ({
  vehicle: getVehicleByMatricula(state, ownProps.matricula),
})
export default connect(mapStateToProps)(VehicleCard)
