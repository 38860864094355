import React, { useContext, useState } from 'react'
import AppContext, { IAppState } from '../../context/context'
import { navigate } from 'gatsby'
import route from '../../utils/route'
import * as selectorActions from '../../context/actions/selectorActions'
import { Step, StepDetails } from '../FunnelSelector/SelectorSteps'
import styles from './vehicleCard.module.scss'
import { t } from '../../i18n'
import ClipLoader from 'react-spinners/ClipLoader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import Size from '../../utils/media-queries-setup'
import { connect } from 'react-redux'
import {
  getAceiteByMatricula,
  getNeumaticoByMatricula,
  getVehicleByMatricula,
  isVehicleLoading,
} from '../../context/selectors/areaPrivadaSelector'
import {
  deleteVehicle,
  setCarRevision,
} from '../../context/actions/areaPrivadaActions'
import { Vehicle } from '../../types/Vehiculo'
import { NeumaticoMatricula } from '../../types/Neumatico'
import { Aceite } from '../../types/Aceite'

interface Props {
  matricula: string
  moreInfo: boolean
  loading: boolean
  vehicle: Vehicle
  neumatico: NeumaticoMatricula | null
  aceite: Aceite | null
}
const VehicleCardActions: React.FC<Props> = ({
  loading,
  moreInfo,
  vehicle,
  neumatico,
  aceite,
}) => {
  const { dispatch } = useContext(AppContext)
  const [loadingNeumatico, setLoadingNeumatico] = useState(false)

  const dispositivo = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'

  const submitRevision = async (vehicle, aceite) => {
    dispatch(setCarRevision(vehicle, aceite))
  }

  const deleteVehicleFnc = async () => {
    await dispatch(deleteVehicle(vehicle.cod_matricula))
  }
  const onClickCambioNeumaticos = async () => {
    // Agregamos los datos del neumático encontrado al context antes de lanzar
    // a la página de búsqueda
    if (neumatico) {
      setLoadingNeumatico(true)
      dispatch(selectorActions.setOrigen('/area-privada/'))
      dispatch(selectorActions.selectFeaturesFromTyre(neumatico))
    } else {
      if (dispositivo === 'MOBILE') {
        await navigate(StepDetails[Step.INITIAL].routes.mobile)
      } else {
        await navigate(StepDetails[Step.INITIAL].routes.desktop)
      }
    }
  }

  const renderDeleteBtn = (isMobile: boolean) => (
    <span
      onClick={deleteVehicleFnc}
      className={`${styles.actions_eliminar} ${
        isMobile ? styles.delete_mobile : styles.delete_desktop
      }`}>
      <FontAwesomeIcon icon={faTrashAlt} />
      {t('area_privada.left_section.eliminar')}
    </span>
  )
  return (
    <div className={styles.actions}>
      {aceite && (
        <p className={styles.info_text}>
          <span>{`${t('area_privada.left_section.ultimo_aceite')}: ${
            aceite.sae
          } ${aceite.normativa}`}</span>
        </p>
      )}
      <div className={styles.solicitar_buttons}>
        <div
          className={styles.solicitar_button}
          onClick={() => submitRevision(vehicle, aceite)}>
          {t('area_privada.left_section.solicitar_revision')}
        </div>
        {loadingNeumatico && (
          <div style={{ marginRight: '10px' }}>
            <ClipLoader size={25} color="#F6D000" loading={loadingNeumatico} />
          </div>
        )}
        <div
          className={styles.solicitar_button}
          onClick={onClickCambioNeumaticos}>
          {t('area_privada.left_section.cambio_neumatico')}
          <br />
          {neumatico && (
            <span>
              {neumatico.ancho}/{neumatico.serie}
              &nbsp;R{neumatico.llanta}&nbsp;
              {neumatico.ic}
              {neumatico.cv}
            </span>
          )}
        </div>
      </div>
      {renderDeleteBtn(false)}
      {moreInfo && !loading && renderDeleteBtn(true)}
      {loading && (
        <div className={styles.loader_container}>
          <ClipLoader size={25} color="#F6D000" loading={loading} />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: IAppState, ownProps) => ({
  loading: isVehicleLoading(state),
  neumatico: getNeumaticoByMatricula(state, ownProps.matricula),
  vehicle: getVehicleByMatricula(state, ownProps.matricula),
  aceite: getAceiteByMatricula(state, ownProps.matricula),
})
export default connect(mapStateToProps)(VehicleCardActions)
